import {
  GoogleTagManagerScript,
  OneTrustScript,
  ThirdPartyScripts,
  TrackingProvider,
} from "@dotcom/ui";
import { Directions, PolestarApp } from "@polestar/component-warehouse-react";
import { Links, Meta, Scripts, ScrollRestoration } from "@remix-run/react";

import { Stage } from "../types";

interface Props {
  readonly children: React.ReactNode;
  readonly languageCode: string;
  readonly stage?: string;
  readonly isChina?: boolean;
  readonly enableRightToLeft?: boolean;
}

export const Head = ({
  stage,
  isChina = true,
}: {
  readonly stage?: string;
  readonly isChina?: boolean;
}) => {
  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta property="og:type" content="website" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
              window._dotcomRUM = {
                errors: [],
                pushToQueue: function(error) {
                  window._dotcomRUM.errors.push(error)
                }
              };
              window.addEventListener('error', window._dotcomRUM.pushToQueue)
          `,
        }}
        type="text/javascript"
      />
      <Meta />
      <Links />
      <ThirdPartyScripts isDevelopment={stage === Stage.Playground}>
        <OneTrustScript isChina={isChina} />
        <GoogleTagManagerScript isChina={isChina} />
      </ThirdPartyScripts>
    </>
  );
};

export const Body = ({
  children,
  enableRightToLeft,
}: {
  readonly children: React.ReactNode;
  readonly enableRightToLeft?: boolean;
}) => {
  return (
    <>
      <PolestarApp
        initialDirection={
          enableRightToLeft ? Directions.rightToLeft : Directions.leftToRight
        }
      >
        <TrackingProvider service="dotcom.legal">{children}</TrackingProvider>
      </PolestarApp>
      <ScrollRestoration />
      <Scripts />
    </>
  );
};

/* v8 ignore start - `html`, `body` and `head` cannot be tested properly with react-testing-library  */
const Document = ({
  children,
  stage,
  isChina,
  languageCode,
  enableRightToLeft,
}: Props) => {
  return (
    <html
      dir={enableRightToLeft ? Directions.rightToLeft : Directions.leftToRight}
      lang={languageCode}
    >
      <head>
        <Head stage={stage} isChina={isChina} />
      </head>
      <body>
        <Body enableRightToLeft={enableRightToLeft}>{children}</Body>
      </body>
    </html>
  );
};
/* v8 ignore stop */

export default Document;
